const { createMuiTheme } = require('@material-ui/core');

const titleFonts = [
  'balooregular',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(',');

const textFonts = [
  'quicksandregular',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(',');

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#313131',
      main: '#272727',
      dark: '#1c1c1c',
      contrastText: '#f3f3f3'
    },
    secondary: {
      main: '#E63946',
      contrastText: '#f3f3f3'
    },
    text: {
      primary: '#f3f3f3',
      secondary: '#e5e5e5',
    }
  },
  typography: {
    fontFamily: textFonts,
    h1: {
      fontFamily: titleFonts,
    },
    h2: {
      fontFamily: titleFonts,
    },
    h3: {
      fontFamily: titleFonts,
    },
    h4: {
      fontFamily: titleFonts,
    },
    h5: {
      fontFamily: titleFonts,
    }
  },
  overrides: {
    MuiButton: {
      label: {
        fontFamily: titleFonts,
      }
    },
    MuiFormControl: {
      root: {
        margin: '16px 0'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#272727',
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: '#f3f3f3'
        }
      }
    }
  },
  props: {
    MuiButton: {
      elevation: 0,
      size: 'large'
    },
    MuiAppBar: {
      elevation: 1
    },
    MuiCard: {
      elevation: 0
    }
  }
});
