import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  ThemeProvider,
  AppBar,
  Button,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Hidden,
  Menu,
  MenuItem,
  Paper,
  Divider
} from '@material-ui/core';

import { BuildRounded as WrenchIcon, MenuRounded as MenuIcon, FavoriteRounded as LoveIcon } from '@material-ui/icons';

import styled from 'styled-components';
import { theme } from '../theme';
import '../global.css';
import '../baloo.css';
import '../quicksand.css';

const AppBarContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  flex: 1 1 auto;
  margin: 0 32px;
`;

const Logo = styled(Box)`
  @keyframes slidein {
    from {
      margin-left: -600px;
      opacity: 0.0;
    }

    to {
      margin-left: 0%;
      opacity: 1.0;
    }
  }
  
  animation-duration: 1000ms;
  animation-delay: 500ms;
  animation-name: slidein;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  margin-left: -600px;
  opacity: 0.0;

  @media screen and (max-width: 900px) {
    height: 54px;
    flex: 0 0 130px;
    background-image: url('/arrow_logo.png');
  }
  @media screen and (min-width: 900px) {
    height: 54px;
    flex: 0 0 287px;
    background-image: url('/arrow_logo_long.png');
  }
  
  margin-right: 8px;
  
  background-size: cover;
  
`;

const Spacer = styled(Box)`
  flex: 1 1 auto;
`;

const StyledCard = styled(Card)`
  animation-duration: 1s;
  animation-delay: ${p => p.index * 500}ms;
  animation-name: fadein;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;  
  opacity: 0.0;

  position: relative;

  @media screen and (min-width: 900px) {
    padding: 32px;
    margin: 16px;
  }
  @media screen and (max-width: 900px) {
    box-shadow: none;
    padding: 16px;
  }
`;

const CardTitle = styled(Typography)`
  padding-bottom: 32px;
`;

const Image = styled.img`
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 16px 0;
`;

const ContactForm = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
`;

const ContactText = styled(TextField)`
  margin: 8px 8px;
`;

const StyledAppBar = styled(AppBar)`
`;

const StyledMenu = styled(Menu)`
  
`;

const StyledMenuItem = styled(MenuItem)`
  min-width: 240px;
`;

const PageLink = styled(Button)`
  animation-duration: 1s;
  animation-delay: ${p => p.index * 500}ms;
  animation-name: fadein;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;  
  opacity: 0.0;
`;

const Footer = styled(Box)`
  height: 24px;
  padding: 24px 0;
  overflow: hidden;
  display: flex;
  background-color: #272727;
  flex-direction: row;
  justify-content: space-evenly;
`;

const FooterSection = styled(Paper)`
`;

const IconText = styled(Typography)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  .MuiSvgIcon-root {
    margin: 0 8px;
  }
`;

const Features = {
  DiyHero: false,
  Team: false
};

export default function Home() {
  const scrollTo = (id) => {
    console.log(`scrolling to ${id}`);
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.scrollY - 80 - 16;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    window.document.title = "Rickety Games";
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledAppBar position="sticky">
        <AppBarContainer>
          <Logo />
          <Spacer />
          <Hidden mdUp>
            <PageLink aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              <MenuIcon fontSize="large" />
            </PageLink>
            <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{horizontal: "right", vertical:"bottom"}}
            >
              <StyledMenuItem onClick={() => scrollTo('projects')}>
                Projects
              </StyledMenuItem>
              <StyledMenuItem onClick={() => scrollTo('about')}>
                About Us
              </StyledMenuItem>
              <StyledMenuItem onClick={() => scrollTo('careers')}>
                Careers
              </StyledMenuItem>
              {Features.Team && (
                <StyledMenuItem onClick={() => scrollTo('team')}>
                  Team
                </StyledMenuItem>
              )}
              <StyledMenuItem onClick={() => scrollTo('contact')}>
                Contact
              </StyledMenuItem>
            </StyledMenu>
          </Hidden>
          <Hidden smDown>
            <PageLink onClick={() => scrollTo('projects')} index={1}>
              Projects
            </PageLink>
            <PageLink onClick={() => scrollTo('about')} index={2}>
              About Us
            </PageLink>
            <PageLink onClick={() => scrollTo('careers')} index={3}>
              Careers
            </PageLink>
            {Features.Team && (
              <PageLink onClick={() => scrollTo('team')} index={4}>
                Team
              </PageLink>
            )}
            <PageLink onClick={() => scrollTo('contact')} index={4}>
              Contact
            </PageLink>
          </Hidden>
        </AppBarContainer>
      </StyledAppBar>
      <Container maxWidth="md">
        <StyledCard id="projects" color="primary" index={1}>
          <CardTitle variant="h4">Projects</CardTitle>
            {!Features.DiyHero && <>
              <Typography variant="body1">
                We have some amazing stuff in development, but we can't share anything concrete about it right now! 
                <br />
                <br />
                We also have a few pitches ready, so if you're a publisher or investor looking for new projects, contact us down below.
                <br />
                <br />
                </Typography>
                <Divider />
                <Typography variant="body1">
                  <br />
                  We do have a sneak peak of something we're working on...
                </Typography>
              <Image src="/project_preview.png" />
            </>
            }
            {Features.DiyHero && <Image src="/diy_hero.png" />}
            {Features.DiyHero && (
              <List>
                <ListItem>
                  <ListItemIcon>
                    <WrenchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Build terrible furniture in VR" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WrenchIcon />
                  </ListItemIcon>
                  <ListItemText primary="A VR first workshop simulator" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WrenchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Levels with objectives to make certain items" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WrenchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Use shop tools such as saws, nails, hammers and glue to build your best creation!" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WrenchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Save and share your monstrosity" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WrenchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Target platforms: Quest, then PC (Oculus Store, Steam)" />
                </ListItem>
              </List>
            )}
        </StyledCard>
        <StyledCard id="about" index={2}>
          <CardTitle variant="h4">About Us</CardTitle>
          <Typography variant="body1">
            Rickety Games was formed by a group of game industry veterans based in the Netherlands.
            With over 40 years combined industry experience and tens of shipped titles between our founders, we know games.
            Our experience ranges from console all the way down to casual web, but our main focus moving forward is PC and Console first.
            <br />
            <br />
            <IconText variant="h5" style={{ display: 'flex' }}>We <LoveIcon /> Fun</IconText>
            <br />
            The goal of Rickety Games is to make fun, pick up and play, gameplay experiences. 
            We're not aiming to take on AAA games, or go completely hypercasual, we're that sweet spot in between.
            We believe that games should make people laugh, cry and smile, sometimes all at the same time.
            <br />
            <br />
            <IconText variant="h5" style={{ display: 'flex' }}>We <LoveIcon /> Remote</IconText>
            <br />
            After playing with the idea for years, we finally joined forces in the summer of 2020.
            We're based in the Netherlands, but work remotely as much as possible.
            <br />
            <Image src="/netherlands.jpg" />
          </Typography>
        </StyledCard>
        <StyledCard id="careers" index={3}>
          <CardTitle variant="h4">Careers</CardTitle>
          <Typography variant="body1">
            We're not currently looking for any new team members, however this might change in the near future!
            <br />
            <Image src="/in_the_door.jpg" />
            <br />
            Who knows, maybe you could be our next Art Intern? Or possibly a new developer? 
            Business development, we're definitely gonna need that!
          </Typography>
        </StyledCard>
        {Features.Team && (
          <StyledCard id="team" index={4}>
            <CardTitle variant="h4">Team</CardTitle>
            <Typography variant="body1"></Typography>
          </StyledCard>
        )}
        <StyledCard id="contact" index={4}>
          <CardTitle variant="h4">Contact</CardTitle>
          <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
            <ContactForm>
              <ContactText label="Name" name="name" type="text" placeholder="Enter your name" required />
              <ContactText label="Email" name="email" type="text" placeholder="Enter your email" required />
              <ContactText
                label="Message"
                name="message"
                type="text"
                placeholder="Enter a message"
                multiline
                rows="7"
                required
              />
            </ContactForm>
            <Button type="submit" variant="contained" color="secondary">
              Submit
            </Button>
          </form>
        </StyledCard>
      </Container>
      <Footer>
        <FooterSection elevation="0">
          <Typography align="center" variant="body1">
            © Rickety Games
          </Typography>
        </FooterSection>
      </Footer>
    </ThemeProvider>
  );
}
